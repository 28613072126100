import React, { Component } from "react";
import {NavLink} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faEllipsisV, faIdCard, faBookReader, faHome } from '@fortawesome/free-solid-svg-icons';

import './Menubar.css'
/* import PDF_Resume from '../../_media/PDF/Darryl Kruiskamp - VResume.pdf'; */

export class Menubar extends Component  {
    state = { menuStyle: { display: 'inline', paddingLeft: '5px'} }

    render () {
        const activeStyle = { color: '#EDF1FE' }

        return (
            <>
                <div className='menubarD d-none d-lg-block'>
                    <nav>
                        <div className="setCursor"><div onClick={this.handleDisp} className="text-overflow"><div style={this.state.menuStyle}>Menu</div> <FontAwesomeIcon icon={faEllipsisV} fixedWidth /></div></div>
                        <div className="border-top"><NavLink to='/' activeStyle={activeStyle}  exact ><div className="text-overflow"><div style={this.state.menuStyle}>Home</div> <FontAwesomeIcon icon={faHome} fixedWidth /></div></NavLink></div>
                        <div className="border-top"><NavLink to='/Bio' activeStyle={activeStyle}  exact ><div className="text-overflow"><div style={this.state.menuStyle}>Bio</div> <FontAwesomeIcon icon={faIdCard} fixedWidth /></div></NavLink></div>
                        <div className="border-top"><NavLink to='/Portfolio' activeStyle={activeStyle} ><div className="text-overflow"><div style={this.state.menuStyle}>Portfolio</div> <FontAwesomeIcon icon={faBookReader} fixedWidth /></div></NavLink></div>
                    </nav>
                </div>
                <div className='menubarM d-block d-lg-none'>
                    <div className="container-fluid">
                        <nav className="row flex-row flex-nowrap">
                            <div className="col-3 text-center"><div onClick={this.handleDisp}><FontAwesomeIcon icon={faEllipsisH} size="2x" fixedWidth /></div><div style={this.state.menuStyle}>Menu</div></div>
                            <div className="col-3 border-left text-center"><NavLink to='/' activeStyle={activeStyle}  exact ><div><FontAwesomeIcon icon={faHome} size="2x" fixedWidth /></div><div style={this.state.menuStyle}>Home</div></NavLink></div>
                            <div className="col-3 border-left text-center"><NavLink to='/Bio' activeStyle={activeStyle}  exact ><div><FontAwesomeIcon icon={faIdCard} size="2x" fixedWidth /></div><div style={this.state.menuStyle}>Bio</div></NavLink></div>
                            {/* <div className="col-3 border-left text-center"><a href={PDF_Resume} ><div><FontAwesomeIcon icon={faFilePdf} size="2x" fixedWidth /></div><div style={this.state.menuStyle}>Resume</div></a></div> */}
                            <div className="col-3 border-left text-center"><NavLink to='/Portfolio' activeStyle={activeStyle}  exact ><div><FontAwesomeIcon icon={faBookReader} size="2x" fixedWidth /></div><div style={this.state.menuStyle}>Portfolio</div></NavLink></div>
                        </nav>
                    </div>
                </div>
            </>
        )
    }

    private handleDisp = () => {
        const menuStyle = { display: 'inline', paddingLeft: '5px'};
        if (this.state.menuStyle.display === 'inline') {
            menuStyle.display = 'none';
        }
        this.setState({menuStyle});
    };
};

export default Menubar;