import React, { Component } from "react";

import './BackgroundClipped.css';

export class BackgroundClipped extends Component  {
    render () {
        var shapes = []
		for(var i=1; i <= 180; i++) {
			// Size
			var randSize = Math.floor(Math.random() * 35) + 15;
			// Speed
			var randDelay = 0;//Math.floor(Math.random() * 3);
			var randDuration = Math.floor(Math.random() * 100) + 15;
			// Position
			var randDepth = -2;
			var randYPos = Math.floor(Math.random() * -180) + 100;
			var randXPos = Math.floor(Math.random() * 90) + 5;
			var randBrightness = Math.min(Math.random(), 0.10) + 0.10;

			var style = {
				left: randXPos+'%',
				bottom: randYPos+'%',
				animationDelay: randDelay+'s',
				animationDuration: randDuration+'s',
				width: randSize+'px',
				height: randSize+'px',
				backgroundColor: 'rgba(184, 155, 114, '+randBrightness+')',
				zIndex: randDepth
			}
			shapes.push(<li id={i.toString()} style={style}></li>);
        }
        
        return (
            <>
                <div className="animation-area">
                    <ul className="animated-shapes" style={{overflow: 'hidden'}}>
                        {shapes}
                    </ul>
                </div>
            </>
        )
    }
};

export default BackgroundClipped;