import React, {FunctionComponent} from "react";
import './NotFound.css'

export const NotFound: FunctionComponent = () => {
    return (
        <>
        <div className='notFound'>
            <h3>| 404 Page Not Found |</h3>
        </div>
        </>
    )
};

export default NotFound;