import React, { Component } from "react";
import './Portfolio.css'

// Temp Objects
import vid1 from '../../_media/VID/guardian.mov';
/* import vid2 from '../../_media/VID/RB142.mov'; */
// Images
import pic1 from '../../_media/IMG/Summative_Shot1.jpg';
import pic2 from '../../_media/IMG/Summative_Shot2.jpg';
import pic3 from '../../_media/IMG/BedroomRender.jpg';


export class Portfolio extends Component {
    render() {
        return (
            <>
            <div className='container-fluid'>
                <div className="row">
                    <div className="portfolio">
                        <div><small>This portfolio website was build from the ground up.</small></div>
                        <div className="text-blue"><small>#React #CSS #HTML #Web #Javascript #Typescript #PHP #Mobile #Desktop</small></div>
                        <div className="hr"></div>

                        <h3>Spaceship Render 1</h3>
                        <div><img className="profileImage" src={pic1} alt="image1" /></div>
                        {/* <div><small>Abandoned spaceship scene featuring lone maintenence robot inspired by Wall-E</small></div> */}
                        <div className="text-blue"><small>#Maya #Rendering #Lighting #Texturing</small></div>
                        <div className="hr"></div>

                        <h3>Spaceship Render 2</h3>
                        <div><img className="profileImage" src={pic2} alt="image1" /></div>
                        {/* <small>Abandoned spaceship scene featuring old robot parts near adjar exit</small> */}
                        <div className="text-blue"><small>#Maya #Rendering #Lighting #Texturing</small></div>
                        <div className="hr"></div>

                        <h3>Bedroom Render</h3>
                        <div><img className="profileImage" src={pic3} alt="image1" /></div>
                        {/* <small>Teen bedroom concept</small> */}
                        <div className="text-blue"><small>#Maya #Modeling #Concept #Rendering #Lighting #Texturing</small></div>
                        <div className="hr"></div>

                        <h3>Galactic Guardian</h3>
                        <div><video className="profileVideo" controls muted controlsList="nodownload" ><source src={vid1} type="video/mp4" /></video></div>
                        {/* <small>Space shooter game buld in 3 weeks</small> */}
                        <div className="text-blue"><small>#Java #OOP #Space #Shooter #Game</small></div>
                        <div className="hr"></div>

                        {/* <h3>Vox Blox Startup</h3>
                        <div><img className="profileImage" src={pic3} alt="image1" /></div>
                        <small>Program / Game for subdividing 3D models into buidlable lego models, with AI for deciding block segments, integration with 3D printers and printout of buid instructions</small>
                        <div className="text-blue"><small>#CSharp #C# #Unity #GameEngine #LEGO #Modles #Obj</small></div>
                        <div className="hr"></div> */}

                        {/* <h3>Robot & Monster</h3>
                        <div><video className="profileVideo" controls muted controlsList="nodownload" ><source src={vid2} type="video/mp4" /></video></div>
                        <small>Exclusing viewing of Nickelodean animated series Robot & Monster</small>
                        <div className="text-blue"><small>#PostProduction</small></div>
                        <div className="hr"></div> */}

                        {/* <h3>Showreel</h3>
                        <div><video className="profileVideo" controls controlsList="nodownload" ><source src={vid} type="video/mp4" /></video></div>
                        <small>Description here</small>
                        <div className="text-blue"><small>#MODELING #TEXTURING #LIGHTING #RENDERING #CONCEPTING #COMPOSITING</small></div>
                        <div className="hr"></div> */}

                        <small>For more work or any feedback please feel free to contact me with the details in the footer.</small>
                    </div>
                </div>
            </div>
            </>
        )
    }
};

export default Portfolio;