import React, {Component} from 'react';
import { Route, Switch, Redirect } from "react-router-dom";

// Components
import { Header, Menubar, Footer, BackgroundExtended, BackgroundClipped } from './components/common';
// --Pages
import { Home, Bio, Resume, Portfolio } from './components/pages';
// --Errors
import { NotFound } from './components/errors';

// Styling
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
/* import { randomBytes } from 'crypto'; */

class App extends Component {
	render() {
		return (
			<div className="App">
				<Header />
				<div className="container">
					<Switch>
						<Route exact path='/Portfolio' component={BackgroundExtended}/>
					</Switch>
					<BackgroundClipped />
					<Switch>
						<Route exact path='/' component={Home}/>
						<Route path='/Bio' component={Bio}/>
						<Route path='/Resume' component={Resume}/>
						<Route path='/Portfolio' component={Portfolio}/>
						<Route component={NotFound}/>
						<Redirect from="*" to="/" />
					</Switch>
				</div>
				<Menubar />
				<Footer />
			</div>
		);
	}
}

export default App;