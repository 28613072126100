import React, { Component } from "react";
import './Resume.css'

import Pdf from '../../_media/PDF/Darryl Kruiskamp - VResume 2020.pdf';

export class Resume extends Component {
    render() {
        return (
            <>
            <div className='container-fluid'>
                <div className="row">
                    <div className="home">
                    <div className = "App">
                        <a href = {Pdf} target = "_blank">Download Pdf</a>
                    </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
};

export default Resume;