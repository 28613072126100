import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import './Bio.css';

import pic1 from '../../_media/IMG/DK_Profile.jpg';
import Pdf from '../../_media/PDF/Darryl Kruiskamp - VResume 2020.pdf';

export class Bio extends Component {
    render() {
        return (
            <>
            <div className='container-fluid'>
                <div className="bio row">
                    <div className="bio_message col-12 col-lg-4">
                        <img className="bio_pic" src={pic1} alt="image1" />
                    </div>
                    <div className="bio_message col-8">
                        <ul>
                            <li><h1>I am <span className="text-blue">Darryl Kruiskamp</span>.</h1></li>
                            <li><p>I'm a Technical Artist with a background in both 3D Animation and Software.</p></li>
                            <li><div className="bio_title">Full Name :</div> Darryl Kruiskamp</li>
                            <li><div className="bio_title">Languages :</div> English, Afrikaans</li>
                            <li><div className="bio_title">Location :</div> Auckland, New Zealand</li>
                            <li><div className="bio_title">Interests :</div> Games, 3D Animation, Movies, Software Development and cooking</li>
                            <li><p></p><a className="btn btn-primary" href={Pdf} download={"Darryl_Kruiskamp_Resume.pdf"} target="_blank"> <FontAwesomeIcon icon={faFilePdf} fixedWidth /> RESUME</a></li>
                            <li>
                                <p></p>
                                <p>
                                    My inspiration for 3D animation and software comes from a love of gaming and creating digital worlds. I love that 3D brings the imagination into an almost tangible existence.
                                </p>
                                <p>
                                    Outside of 3D and software, I love to cook, enjoy a good South African braai, and playing with the lighting display on stage at church.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            </>
        )
    }
};

export default Bio;