import React, {FunctionComponent} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

import './Footer.css'

export const Footer: FunctionComponent = () => {
    return (
        <>
        <footer className='footerM d-block d-lg-none'>
            <div><b><FontAwesomeIcon icon={faMobileAlt} fixedWidth /></b>&nbsp; <a href="tel:02102774084">+64 21 027 74 084</a></div>
            <div><b><FontAwesomeIcon icon={faEnvelope} fixedWidth /></b>&nbsp;<a href="mailto: darryl.kruiskamp@gmail.com?subject=Job Inquiry&body=Hi Darryl,">darryl.kruiskamp@gmail.com</a></div>
            <div><b><FontAwesomeIcon icon={faLinkedin} fixedWidth /></b>&nbsp;<a href="https://www.linkedin.com/in/darryl-kruiskamp">Darryl Kruiskamp</a></div>
        </footer>
        <footer className='footerD d-none d-lg-block'>
            <b><FontAwesomeIcon icon={faMobileAlt} fixedWidth /></b>&nbsp; <a href="tel:02102774084">+64 21 027 74 084</a>&nbsp;|&nbsp;
            <b><FontAwesomeIcon icon={faEnvelope} fixedWidth /></b>&nbsp;<a href="mailto: darryl.kruiskamp@gmail.com?subject=Job Inquiry&body=Hi Darryl,">darryl.kruiskamp@gmail.com</a>&nbsp;|&nbsp;
            <b><FontAwesomeIcon icon={faLinkedin} fixedWidth /></b>&nbsp;<a href="https://www.linkedin.com/in/darryl-kruiskamp">Darryl Kruiskamp</a>
        </footer>
        </>
    )
};

export default Footer;