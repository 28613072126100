import React, { Component } from "react";
import './Home.css'

export class Home extends Component {
    render() {
        return (
            <>
            <div className='container-fluid'>
                <div className="row">
                    <div className="home">
                        <h1>Hi, I am <span className="text-blue">Darryl Kruiskamp</span>.</h1>
                        <h3>I'm a Technical Artist with a background in both 3D Animation and Software Development.</h3>
                    </div>
                </div>
            </div>
            </>
        )
    }
};

export default Home;