import React, {FunctionComponent} from "react";
/* import {NavLink} from "react-router-dom"; */
import './Header.css'

export const Header: FunctionComponent = () => {
    /* 
    const activeStyle = {
        color: '#EDF1FE'
    }
     */
    return (
        <nav className='header'>
            {/*
            <NavLink to='/' activeStyle={activeStyle}  exact >PROFILE</NavLink>&nbsp;
            { " | "}&nbsp;
            <NavLink to='/examples' activeStyle={activeStyle} >EXAMPLES</NavLink>
            */}
            <b>Darryl Kruiskamp</b>
        </nav>
    )
};

export default Header;